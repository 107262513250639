import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src74448501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typeaheads"
    }}>{`Typeaheads`}</h1>
    <p>{`The common package provides a number of Typeahead components that can be used to display a select or multi-select style field
where the options are dynamically fetched or filtered based on the user's input.`}</p>
    <p>{`There are 5 different variants of the Typeahead component, which vary in what data is used for the options.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`BridgeTypeahead`}</inlineCode>{` - Uses data fetched from a bridged resource.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`FormTypeahead`}</inlineCode>{` - Uses a list of Kinetic forms.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`StaticTypeahead`}</inlineCode>{` - Uses a static list of data provided as a prop.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`TeamTypeahead`}</inlineCode>{` - Uses a list of Kinetic teams.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`UserTypeahead`}</inlineCode>{` - Uses a list of Kinetic users.`}</li>
    </ul>
    <p>{`The majority of the props are the same for all of these components, and are defined in the Common Props section below.
Any props specific to individual components are defined further below.`}</p>
    <h2 {...{
      "id": "common-props"
    }}>{`Common Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`value -> object | object[]`}</inlineCode>{` = The value object, or an array of objects if multiple are allowed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onChange -> option => null`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` Function triggered when a new option is selected that takes the selected option (as an object) and sets the value.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onFocus -> () => null`}</inlineCode>{` = Function triggered when the input is focused.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onBlur -> () => null`}</inlineCode>{` = Function triggered when the input is blurred.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`id -> string`}</inlineCode>{` = Value to set as the HTML id attribute on the input.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`placeholder -> string`}</inlineCode>{` = Value to set as the placeholder of the input.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disabled -> boolean`}</inlineCode>{` = Flag for disabling the typeahead input.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`multiple -> boolean`}</inlineCode>{` = Flag for allowing multiple options to be selected.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`allowNew -> boolean | value => boolean`}</inlineCode>{` = Flag for allowing the user to type in a custom option. Accepts a boolean or a validation function that receives the string value typed in by the user and must return true for the value to be accepted as a valid option.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`minSearchLength -> number`}</inlineCode>{` = The minimum number of characters that need to be entered before options are shown. Defaults to 0 (except for BridgeTypeahed where it defaults to 1).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`messages -> object`}</inlineCode>{` = Overrides for the status messages that show up in the typeahead.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`short -> string`}</inlineCode>{` = Shown when not enough characters have been typed in to trigger a search.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`empty -> string`}</inlineCode>{` = Shown when no results found and allowNew is false.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`custom -> string`}</inlineCode>{` = Shown when no results found and allowNew is a function that returned false. Use to tell the user what valid, custom options they can type in.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`pending -> string`}</inlineCode>{` = Shown when searching is in progress.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`more -> string`}</inlineCode>{` = Shown when there are too many results to show all.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`error -> string`}</inlineCode>{` = Shown when an error occurs when fetching data. Not applicable for StaticTypeahead.`}</li>
        </ul></li>
      <li parentName="ul"><inlineCode parentName="li">{`components -> object`}</inlineCode>{` = Overrides for the components used to render the typeahead.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`Input -> component`}</inlineCode>{` = Renders the input element.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Selection -> component`}</inlineCode>{` = Renders the selected value (or each value if multiple are allowed).`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`SelectionsContainer -> component`}</inlineCode>{` = Wrapper for the selection and input elements.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Status -> component`}</inlineCode>{` = Renders the status messages inside the suggestions container, before the suggestions.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Suggestion -> component`}</inlineCode>{` = Renders a single suggestion.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`SuggestionContent -> component`}</inlineCode>{` = Renders the content of a suggestion. Only used if the Suggestion component is not overridden.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`SuggestionsContainer -> component`}</inlineCode>{` = Wrapper for the suggestions and status messages.`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "bridgetypeahead-props"
    }}>{`BridgeTypeahead Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`search -> object`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` Configurations for the bridge to be used when searching for data.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`formSlug -> string`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` Slug of form where the bridgedResource is defined.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`kappSlug -> string`}</inlineCode>{` = Slug of kapp where the bridgedResource is defined. Omit if the bridgedResource is defined on a datastore form.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`datastore -> boolean`}</inlineCode>{` = Flag to specify that the formSlug is for a datastore form.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`bridgedResourceName -> string`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` The name of the bridgedResource.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`fields -> object[]`}</inlineCode>{` = Array of configurations for field values to pass to the bridgedResource if any parameters are needed.`}<ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`name -> string`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` The name of the field to which a value should be passed.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`value -> string`}</inlineCode>{` = A static value to pass to the field. If omitted, the user's typed in query string will be set as the value.`}</li>
            </ul></li>
          <li parentName="ul"><inlineCode parentName="li">{`limit -> number`}</inlineCode>{` = The max number of options to display at once. This does not affect the bridge query, just how many of the bridge results are displayed. Showing too many results leads to performance issues. Defaults to 25.`}</li>
        </ul></li>
      <li parentName="ul"><inlineCode parentName="li">{`valueProp -> string`}</inlineCode>{` = The property of the value object that should be used as a unique identifier of each option. Used when allowNew is true to build a an object out of the user's typed in value. Defaults to 'value'.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`labelProp -> string`}</inlineCode>{` = The property of the value object that should be shown in the typeahead inputs and the default suggestion list. Defaults to the value of valueProp if provided, or to 'label' otherwise.`}</li>
    </ul>
    <h2 {...{
      "id": "formtypeahead-props"
    }}>{`FormTypeahead Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`search -> object`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` Configurations for the search.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`kappSlug -> string`}</inlineCode>{` = Slug for which kapp's forms to query.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`datastore -> boolean`}</inlineCode>{` = Flag to specify that we should query datastore forms instead of kapp forms.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`fields -> object[]`}</inlineCode>{` = Array of configurations for form properties to search against.`}<ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`name -> string`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` The name of the form property to which a value should be passed.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`value -> string | string[]`}</inlineCode>{` = A static value to pass to the form property. If omitted, the user's typed in query string will be set as the value. If using the in operator, an array should be passed in as the value.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`operator -> string`}</inlineCode>{` = The operator to use when searching this form property. Valid options: "equals", "matches" (contains), "in", or "startsWith". Defaults to "startsWith". "in" is only available if you pass a value.`}</li>
            </ul></li>
          <li parentName="ul"><inlineCode parentName="li">{`limit -> number`}</inlineCode>{` = The max number of options to display at once. The api call will only fetch the set number of forms. Showing too many results leads to performance issues. Defaults to 25.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`include -> string`}</inlineCode>{` = String value to pass to the include parameter of the query.`}</li>
        </ul></li>
      <li parentName="ul"><inlineCode parentName="li">{`labelProp -> string`}</inlineCode>{` = The property of the value object that should be shown in the typeahead inputs and the default suggestion list. Defaults to 'slug'.`}</li>
    </ul>
    <h2 {...{
      "id": "statictypeahead-props"
    }}>{`StaticTypeahead Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`options -> object[]`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` Array of options objects (or an immutable List). Used as the data for the typeahead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`search -> object`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` Configurations for the search.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`fields -> object[]`}</inlineCode>{` = Configuration for properties that will be searched on each options object. Not used when search.fn is provided.`}<ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`name -> string`}</inlineCode>{` = `}<em parentName="li"><strong parentName="em">{`Required.`}</strong></em>{` The name of the form property to which a value should be passed.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`operator -> string`}</inlineCode>{` = The operator to use when searching in this field. Valid options: "equals", "matches" (contains), or "startsWith". Defaults to "startsWith".`}</li>
            </ul></li>
          <li parentName="ul"><inlineCode parentName="li">{`fn -> (options, value) => object[]`}</inlineCode>{` = Function that accepts an array of options and the typed in user value and returns a filtered list of options.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`limit -> number`}</inlineCode>{` = The max number of options to display at once. Showing too many results leads to performance issues. Defaults to 25.`}</li>
        </ul></li>
      <li parentName="ul"><inlineCode parentName="li">{`valueProp -> string`}</inlineCode>{` = The property of the value object that should be used as a unique identifier of each option. Used when allowNew is true to build a an object out of the user's typed in value. Defaults to 'value'.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`labelProp -> string`}</inlineCode>{` = The property of the value object that should be shown in the typeahead inputs and the default suggestion list. Defaults to the value of valueProp if provided, or to 'label' otherwise.`}</li>
    </ul>
    <h2 {...{
      "id": "teamtypeahead-props"
    }}>{`TeamTypeahead Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`search -> object`}</inlineCode>{` = Configurations for the search.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`limit -> number`}</inlineCode>{` = The max number of options to display at once. The api call will only fetch the set number of teams. Showing too many results leads to performance issues. Defaults to 25.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`include -> string`}</inlineCode>{` = String value to pass to the include parameter of the api call.`}</li>
        </ul></li>
      <li parentName="ul"><inlineCode parentName="li">{`labelProp -> string`}</inlineCode>{` = The property of the value object that should be shown in the typeahead inputs and the default suggestion list. Defaults to 'name'.`}</li>
    </ul>
    <h2 {...{
      "id": "usertypeahead-props"
    }}>{`UserTypeahead Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`search -> object`}</inlineCode>{` = Configurations for the search.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`limit -> number`}</inlineCode>{` = The max number of options to display at once. The api call will only fetch the set number of users. Showing too many results leads to performance issues. Defaults to 25.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`include -> string`}</inlineCode>{` = String value to pass to the include parameter of the api call.`}</li>
        </ul></li>
      <li parentName="ul"><inlineCode parentName="li">{`labelProp -> string`}</inlineCode>{` = The property of the value object that should be shown in the typeahead inputs and the default suggestion list. Defaults to 'username'.`}</li>
    </ul>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
import {
  BridgeTypeahead,
  FormTypeahead,
  StaticTypeahead,
  TeamTypeahead,
  UserTypeahead,
} from '@kineticdata/bundle-common';

class TypeaheadExamples extends React.Component {
  constructor(props) {
    super(props);
    // State for value and example structures of the data
    this.state = {
      bridgeValue: null, // { Data: '', Label: '', Id: '' }
      formValue: // { name: 'Comment', slug: 'comment' }, // { name: '', slug: '' }
      staticValue: null, // { code: '', name: '' }
      teamValue: null, // { name: '' }
      userValues: [], // [{ displayName: '', username: '' }]
    };
  }

  render() {
    return (
      <div>

        <BridgeTypeahead
          value={this.state.bridgeValue}
          onChange={v => this.setState({ bridgeValue: v })}
          search={{
            formSlug: 'shared-resources',
            kappSlug: 'services',
            bridgedResourceName: 'Search Sample Data',
            fields: [{ name: 'Query' }],
          }}
          valueProp: 'Data',
        />

        <FormTypeahead
          value={this.state.formValue}
          onChange={v => this.setState({ formValue: v })}
          search={{
            kappSlug: 'services',
            fields: [
              { name: 'status', operator: 'in', value: ['Active', 'New'] },
              { name: 'name', operator: 'matches' },
              { name: 'category' },
            ],
            include="categorizations"
          }}
          placeholder="Select a form by name of category"
        />

        <StaticTypeahead
          value={this.state.staticValue}
          onChange={v => this.setState({ staticValue: v })}
          options={this.props.locales} // List of locales from /app/api/v1/meta/locales
          search={{
            kappSlug: 'services',
            fields: [
              { name: 'code', operator: 'equals' },
              { name: 'name', operator: 'matches' },
            ],
          }}
          valueProp="code"
          labelProp="name"
          minSearchLength={2}
          messages={{
            short: 'Enter at least 2 characters to find locales.',
            empty: 'No matching locales found.',
            pending: 'Finding matching locales...'
          }}
        />

        <TeamTypeahead
          value={this.state.teamValue}
          onChange={v => this.setState({ teamValue: v })}
          allowNew={v => v.startsWith('Custom::')}
          messages={{
            custom: 'No matching teams found. You can enter a custom team by prefixing it with "Custom::".',
          }}
        />

        <UserTypeahead
          value={this.state.userValues}
          onChange={v => this.setState({ userValues: v })}
          multiple={true}
        />

      </div>
    );
  }
}
`}</code></pre>
    <h2 {...{
      "id": "overriding-components"
    }}>{`Overriding Components`}</h2>
    <p>{`You can override any of the default components to make the typeahead look anyway you want.
Below is the code for example components so you can see how each one is implemented.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Input = props => (
  <input
    {...props.inputProps}
    className="form-control"
    placeholder={getIn(props, ['inputProps', 'selection', 'value'])}
  />
);

const SelectionsContainer = ({ selections, input, multiple }) => (
  <div className={classNames('kinetic-typeahead', { multi: multiple })}>
    {selections}
    {input}
  </div>
);

// Passed in as the Selection component when the multiple prop is true.
const MultiSelection = ({ selection, remove }) => (
  <div className="input-group selection">
    <input
      className="form-control"
      type="text"
      disabled
      value={selection.get('value')}
    />
    <div className="input-group-append">
      <button className="btn btn-sm btn-clear" onClick={remove} type="button">
        <span className="sr-only">Remove</span>
        <i className="fa fa-fw fa-times" />
      </button>
    </div>
  </div>
);

// Passed in as the Selection component when the multiple prop is false.
export const SingleSelection = ({
  selection,
  disabled,
  edit,
  focusRef,
  remove,
}) => (
  <div className="input-group selection">
    <input
      className="form-control"
      type="text"
      value={selection ? selection.get('value') : ''}
      onChange={() => {}}
      onFocus={e => e.target.click()}
      onClick={edit}
      disabled={disabled}
    />
    {selection && !disabled && (
      <div className="input-group-append">
        <button className="btn btn-sm btn-clear" onClick={remove} type="button">
          <span className="sr-only">Remove</span>
          <i className="fa fa-fw fa-times" />
        </button>
      </div>
    )}
  </div>
);

export const Status = props => (
  <div>
    {props.meta && (
      <div className="status meta">
        <I18n>{props.meta}</I18n>
        {props.clearFilterField && (
          <button className="btn btn-sm" onClick={props.clearFilterField}>
            <i className="fa fa-fw fa-remove" />
          </button>
        )}
      </div>
    )}
    {props.info && (
      <div className="status info">
        <I18n>{props.info}</I18n>
      </div>
    )}
    {props.warning && (
      <div className="status warning">
        <i className="fa fa-fw fa-exclamation-triangle" />
        <I18n>{props.warning}</I18n>
      </div>
    )}
  </div>
);

export const Suggestion = ({ suggestion, active, custom }) => (
  <div className={\`suggestion \${active ? 'active' : ''}\`}>
    <div className={classNames('large', { 'font-italic': custom })}>
      {suggestion.get('value')}
    </div>
  </div>
);

const SuggestionContent = ({ suggestion }) => (
  <>
    <div className={classNames('large', { 'font-italic': custom })}>
      {suggestion.get('value')}
    </div>
  </>
);

export const SuggestionsContainer = ({ open, children, containerProps }) => (
  <div {...containerProps} className={\`suggestions \${open ? 'open' : ''}\`}>
    {children}
  </div>
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      